import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/Layout.js'
import Button from '../components/Button.js'

const Music = () => {
  const [activeSection, setActiveSection] = useState(null);
  const whyMusicStreamingRef = useRef(null);
  const howItWorksRef = useRef(null);
  const pirateRadioRef = useRef(null);

  const sections = [
    { name: 'whyMusicStreaming', ref: whyMusicStreamingRef },
    { name: 'howItWorks', ref: howItWorksRef },
    { name: 'pirateRadio', ref: pirateRadioRef },
  ];

  const handleScroll = () => {
    const currentSection = sections.find(section => {
      const element = section.ref.current;
      if (element) {
        const { offsetTop } = element;
        const screenPosition = window.scrollY + window.innerHeight / 2;
        return screenPosition > offsetTop && element.getBoundingClientRect().bottom >= 50;
      }
      return false;
    });

    setActiveSection(currentSection ? currentSection.name : null);
  };

  const handleClick = (jumpToSectionRef) =>{
    jumpToSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    const currentSection = sections.find(section =>{
      return section.ref === jumpToSectionRef;
    })
    setActiveSection(currentSection.name)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
      <Layout title="Music" marginBottomClass={"mb-0"}>
          <section className="font-mono xl:px-12">
              <h1 className="text-white mx-auto mt-[50px] mb-[50px] text-[50px] tracking-wide max-md:text-[30px] max-md:mt-[30px] max-md:mb-[50px] md:max-lg:text-[30px]">
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                  Where the sound of independence
                  </span>
                  <br />
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                   meets the beat of innovation
                  </span>
              </h1>
              <div className="flex flex-col border-t border-gray-700 md:flex-row">
                  <div className=" hidden w-0 lg:h-full lg:sticky lg:top-[90px] lg:w-1/4 lg:justify-left lg:items-center lg:flex lg:flex-1 lg:text-2xl lg:mt-4">
                      <ul>
                          <li
                              className={`${
                                  activeSection === "whyMusicStreaming"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(whyMusicStreamingRef)}
                          >
                              Why Music Streaming?
                          </li>
                          <li
                              className={`${
                                  activeSection === "howItWorks"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(howItWorksRef)}
                          >
                              How Effem Works
                          </li>
                          <li
                              className={`${
                                  activeSection === "pirateRadio"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(pirateRadioRef)}
                          >
                              Pirate Radio
                          </li>
                      </ul>
                  </div>
                  <div className="w-full pl-1.5 lg:border-l lg:border-gray-700 lg:w-3/4 lg:pl-10">
                      <div id="whyMusicStreaming" ref={whyMusicStreamingRef} className="pt-16">
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                              Why Music Streaming?
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                          In today's music industry, the odds are stacked against the very artists who fuel its creativity. Many streaming platforms pay artists a paltry fraction of a penny per stream, leaving all but the biggest billboard names struggling to make ends meet. 
These platforms are controlled by three major music labels, whose primary goal is to promote their superstar artists and maintain dominance over visibility and playlisting. This unfair practice sidelines independent and emerging artists, hindering their chances of gaining recognition and exposure.
To put things in perspective, an artist would need around 5,000,000 streams just to meet the U.S. minimum wage threshold—before even factoring in cuts from labels, management, and publishing. <em>It's a system that's anything but sustainable.</em>
</p>
<p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
<strong>But it doesn't have to be this way.</strong> Enter Effem—a beacon of change in a landscape dominated by giants. Effem isn't just another streaming platform; it's a movement. It's about putting the power back in the hands of the artists and their fans.
Instead of fixating on stream numbers, Effem prioritizes the meaningful connection between artist and fanbase. By shifting the focus from streaming numbers to fan relationships, we're not just fostering better art—we're revolutionizing the way music is discovered, shared, and supported. Empowering artists and fans to exchange music and support directly, instantaneously, and free from censorship or middlemen.
Today's music discovery landscape is a chaotic mess, controlled by the big three majors and their corporate interests. But Effem refuses to play by their rules. We believe that finding and sharing great music should be driven by fans, not by corporate suits pulling the strings behind the scenes. 
Artists should own their own data, not the platform. For this reason we are bringing full-transparency to real-time data and ownership, allowing artists to better understand who their super fans are and serve those who matter most.
Effem is more than just a platform; it's a declaration of independence for artists and their fans. It's about reclaiming ownership of music and creating a space where talent, not corporate agendas, reign supreme. So join us as we rewrite the rules and pave the way for a future where artists thrive and music truly belongs to the people.
Effem for the artists. Effem for the fans. Effem for the music. And if the suits don’t like it, Effem! 
                          </p>
                      </div>
                      <div
                          id="how-it-works"
                          ref={howItWorksRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                          How Effem Works
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                          <ol>
  <li><strong>1. Artists Host on Effem Relay</strong><br />Artists utilize Effem Relay, an open source and decentralized platform, to host and distribute their music content, leveraging the power of the Nostr protocol.</li>
  <li><strong>2. Artists Set Payment Preferences</strong><br />Artists have the flexibility to set their preferred payment model for their music streams, such as 'Value for Value' (V4V), monthly allocation, or fixed pricing, empowering them to choose the monetization strategy that best suits their needs.</li>
  <li><strong>3. Direct Fan-to-Artist Payments</strong><br />Using the Bitcoin Lightning Network, a fast and secure payment system, listeners can directly pay artists for music streams, eliminating intermediaries and ensuring a fair share of revenue for the creators, fostering a direct connection between fans and artists. </li>
</ol>

                          </p>
                      </div>
                      <div
                          id="pirate-radio"
                          ref={pirateRadioRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                          Pirate Radio
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              We like to think of Effem as Pirate Radio for the 21st century.
                              Unlike original pirate radio, streaming stolen music over airways,
                              Effem isn’t about stealing music but empowering artists to advocate 
                              for themselves and earn what they are owed without any middleman rent 
                              collector stealing value from them. 
                              <strong>We’re stealing value from the record labels and getting it into the hands of the artists!</strong>
                          </p>
                      </div>
                      <div className="pt-16 pb-24">
                          <Button
                              text="Contact Us"
                              link="/contact"
                              variant="gradient"
                              shadow={true}
                          />
                      </div>
                  </div>
              </div>
          </section>
      </Layout>
  );
}

export default Music