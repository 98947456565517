import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module'

import Home from './pages/Home.js';
import NotFoundPage from './pages/NotFoundPage.js';
import TechStack from './pages/TechStack.js';
import Music from './pages/Music.js';
import Contact from './pages/Contact.js';
import Manifesto from './pages/Manifesto.js';
import Cloud from './pages/Cloud.js';

const tagManagerArgs = { gtmId: 'GTM-TQJ2ZJ6J' };
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <>
    <Router>
          <Routes>
            <Route path="/music" element={<Music />} />
            <Route path="/tech-stack" element={<TechStack />} />
            <Route path="/manifesto" element={<Manifesto />} />
            <Route path="/contact" element={<Contact />}/>
            <Route path="/cloud" element={<Cloud />}/>
            <Route path="/" element={<Home />} /> {/* Default Route */}
            <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
          </Routes>
        </Router>
    </>
  );
}

export default App;
