import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/Layout.js'
import Button from '../components/Button.js'

const TechStack = () => {
  const [activeSection, setActiveSection] = useState(null);
  const overviewRef = useRef(null);
  const whyBitcoinRef = useRef(null);
  const whyNostrRef = useRef(null);
  const whySwiftRef = useRef(null);
  const whyOpenSourceRef = useRef(null);

  const sections = [
    { name: 'overview', ref: overviewRef },
    { name: 'whyBitcoin', ref: whyBitcoinRef },
    { name: 'whyNostr', ref: whyNostrRef },
    { name: 'whySwift', ref: whySwiftRef },
    { name: 'whyOpenSource', ref: whyOpenSourceRef },
  ];

  const handleScroll = () => {
    const currentSection = sections.find(section => {
      const element = section.ref.current;
      if (element) {
        const { offsetTop } = element;
        const screenPosition = window.scrollY + window.innerHeight / 2;
        return screenPosition > offsetTop && element.getBoundingClientRect().bottom >= 50;
      }
      return false;
    });

    setActiveSection(currentSection ? currentSection.name : null);
  };

  const handleClick = (jumpToSectionRef) =>{
    jumpToSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    const currentSection = sections.find(section =>{
      return section.ref === jumpToSectionRef;
    })
    setActiveSection(currentSection.name)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
      <Layout title="Tech-Stack" marginBottomClass={"mb-0"}>
          <section className="font-mono xl:px-12">
              <h1 className="text-white mx-auto mt-[50px] mb-[50px] text-[50px] tracking-wide max-md:text-[30px] max-md:mt-[30px] max-md:mb-[50px] md:max-lg:text-[30px]">
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                  The Effem 
                  </span>
                  <br />
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                  Tech Stack
                  </span>
              </h1>
              <div className="flex flex-col border-t border-gray-700 md:flex-row">
                  <div className=" hidden w-0 lg:h-full lg:sticky lg:top-[90px] lg:w-1/4 lg:justify-left lg:items-center lg:flex lg:flex-1 lg:text-2xl lg:mt-4">
                      <ul>
                          <li
                              className={`${
                                  activeSection === "overview"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(overviewRef)}
                          >
                              Overview
                          </li>
                          <li
                              className={`${
                                  activeSection === "whyBitcoin"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(whyBitcoinRef)}
                          >
                              Why Bitcoin
                          </li>
                          <li
                              className={`${
                                  activeSection === "whyNostr"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(whyNostrRef)}
                          >
                              Why Nostr
                          </li>
                          <li
                              className={`${
                                  activeSection === "whySwift"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(whySwiftRef)}
                          >
                              Why Swift
                          </li>
                          <li
                              className={`${
                                  activeSection === "whyOpenSource"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(whyOpenSourceRef)}
                          >
                              Why Open Source
                          </li>
                      </ul>
                  </div>
                  <div className="w-full pl-1.5 lg:border-l lg:border-gray-700 lg:w-3/4 lg:pl-10">
                      <div id="overview" ref={overviewRef} className="pt-16">
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                              Overview
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                          Our mission:
                          Use open, permissionless, and censorship resistant protocols to 
                          distribute value from consumer to creator.
                          To accomplish this mission we are building on open, decentralized protocols,
                          like Bitcoin and Nostr. 

                          </p>
                      </div>
                      <div
                          id="why-bitcoin"
                          ref={whyBitcoinRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                              Why Bitcoin
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              Why lightning? Instant and low fee bitcoin transactions. 
                              Why bitcoin? Permissionless, censorship resistant payments 
                              to our artists. Low friction, no rent collectors stealing 
                              value from the artists. Not even us!

                          </p>
                      </div>
                      <div
                          id="why-nostr"
                          ref={whyNostrRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                          Why Nostr
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              This is simple. Nostr is the open internet protocol 
                              embraced by the bitcoin community. It is completely 
                              censorship resistant. It has an open source philosophy 
                              and a talented builder community that can help propel 
                              Effem post launch with other teams running Relays that
                              support the Effem Nostr implementation possibility - NIP(s). 
                              Imagine one day the big 3 music labels running their own 
                              relays to be a part of the Effem music streaming revolution.

                          </p>
                      </div>
                      <div
                          id="why-swift"
                          ref={whySwiftRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                              Why Swift
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                             So many Bitcoin lightning dev tools are developed with Rust and Go, 
                             and to a lesser extent javascript. This is fine and the Rust 
                             projects (Lightning Dev Kit & Breez SDK) both have FFI Swift bindings but 
                             this is not ideal for normal iOS developers. Why? Your average iOS dev does 
                             not know Rust so they cannot contribute to the SDK. Yes, they can contribute 
                             to the binding framework but this really isn’t contributing to the lightning logic.
                             Nostr does have the wonderful Nostr-sdk-ios framework. But so much of the Nostr tools 
                             are also Rust, Go, Javascript, and C. So why Swift? iOS is the dominant platform in 
                             the United States and in our opinion is the superior mobile operating system. We want to 
                             empower iOS devs to bring Nostr and Bitcoin lightning into their apps in a way that they 
                             can contribute back to the community and feel like they understand the frameworks they 
                             are including in their apps. We think there is a real opportunity to bring a lot of strong 
                             Swift developers into the bitcoin and Nostr ecosystems if the tools are approachable. 

                             On top of all that, Swift is a very powerful and capable programming language. It has a 
                             bad reputation being so tightly coupled with Apple. But the team at 
                             <span className="text-gradient from-purple-600 to-pink-600">
                             <a href="https://swift.cloud"> Swift Cloud</a></span> have been doing a lot of
                             great work to bring Swift to the masses. Swift has a lot of the speed 
                             and power of Rust, but with a much easier to understand and more ergonomic syntax that 
                             is a much better developer experience. Bringing this power and ease of use to Lightning 
                             and Nostr will help accelerate developer adoption.

                          </p>
                      </div>
                      <div
                          id="why-open-source"
                          ref={whyOpenSourceRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                          Why Open Source
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                              In the spirit of Bitcoin and Nostr and to have 
                              credibility in this space our code must be free 
                              and open. It buys us credibility but at the same 
                              time it allows us to develop tools for the Lightning 
                              and Nostr communities and if they are growing then 
                              Effem is growing. Open source also allows others to 
                              easily spin up relays that can communicate with our app.
                          </p>
                      </div>
                      <div className="pt-16 pb-24">
                          <Button
                              text="Contact Us"
                              link="/contact"
                              variant="gradient"
                              shadow={true}
                          />
                      </div>
                  </div>
              </div>
          </section>
      </Layout>
  );
}

export default TechStack