import React, { useEffect, useRef, useState } from 'react'
import Layout from '../components/Layout.js'
import Button from '../components/Button.js'

const Manifesto = () => {
  const [activeSection, setActiveSection] = useState(null);
  const northStarRef = useRef(null);
  const whyMusicRef = useRef(null);
  const intoTheFutureRef = useRef(null);

  const sections = [
    { name: 'northStar', ref: northStarRef },
    { name: 'whyMusic', ref: whyMusicRef },
    { name: 'intoTheFuture', ref: intoTheFutureRef },
  ];

  const handleScroll = () => {
    const currentSection = sections.find(section => {
      const element = section.ref.current;
      if (element) {
        const { offsetTop } = element;
        const screenPosition = window.scrollY + window.innerHeight / 2;
        return screenPosition > offsetTop && element.getBoundingClientRect().bottom >= 50;
      }
      return false;
    });

    setActiveSection(currentSection ? currentSection.name : null);
  };

  const handleClick = (jumpToSectionRef) =>{
    jumpToSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    const currentSection = sections.find(section =>{
      return section.ref === jumpToSectionRef;
    })
    setActiveSection(currentSection.name)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  return (
      <Layout title="north-star" marginBottomClass={"mb-0"}>
          <section className="font-mono xl:px-12">
              <h1 className="text-white mx-auto mt-[50px] mb-[50px] text-[50px] tracking-wide max-md:text-[30px] max-md:mt-[30px] max-md:mb-[50px] md:max-lg:text-[30px]">
                  <span className="text-gradient from-purple-600 to-pink-600 pl-1.5 pr-1.5">
                  Effem Manifesto
                  </span>
              </h1>
              <div className="flex flex-col border-t border-gray-700 md:flex-row">
                  <div className=" hidden w-0 lg:h-full lg:sticky lg:top-[90px] lg:w-1/4 lg:justify-left lg:items-center lg:flex lg:flex-1 lg:text-2xl lg:mt-4">
                      <ul>
                          <li
                              className={`${
                                  activeSection === "northStar"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(northStarRef)}
                          >
                              North Star
                          </li>
                          <li
                              className={`${
                                  activeSection === "whyMusic"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(whyMusicRef)}
                          >
                              Why music?
                          </li>
                          <li
                              className={`${
                                  activeSection === "intoTheFuture"
                                      ? "text-white"
                                      : "text-gray-500"
                              } cursor-pointer my-6 text-base font-light hover:text-white`}
                              onClick={() => handleClick(intoTheFutureRef)}
                          >
                              Into The Future
                          </li>
                      </ul>


                  </div>
                  <div className="w-full pl-1.5 lg:border-l lg:border-gray-700 lg:w-3/4 lg:pl-10">
                      <div id="northStar" ref={northStarRef} className="pt-16">
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                              North Star
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">
                          Effem embodies a relentless commitment to leveraging open, permissionless, and censorship-resistant 
                          protocols to revolutionize value distribution from consumers to creators. Our mission is 
                          laser-focused: to pioneer a new era of empowerment and connectivity through decentralized and groundbreaking technology.<br /><br />
                          
                          Let's delve into the essence of our name, Effem. It serves as a dual symbol of our purpose. Firstly, 
                          it nods to the traditional FM radio where music discovery was and to a certain extent still is 
                          popular. Secondly, it embodies a rebellious spirit, encapsulating a “F*ck ‘em” defiance against the 
                          status quo. Effem’s vision extends far beyond a music streaming platform; it's a rallying cry for 
                          freedom in the digital age.<br /><br />
 
                          While our initial focus lies in empowering emerging and independent artists, our ambition transcends 
                          the realms of music. Effem is a catalyst for ushering the first billion people into the realm of Bitcoin. 
                          We envision a future where Bitcoin serves as the cornerstone of a liberated internet, untethered from 
                          the shackles of centralized control.<br /><br />
  
                          Unlike conventional approaches in the Bitcoin space, Effem is not content with merely building wallet 
                          infrastructure. We are pioneers of user-centric products that seamlessly integrate Bitcoin into everyday 
                          experiences. By aligning user incentives with Bitcoin adoption, we pave the way for wider adoption.<br /><br />

                          Many of you reading this know that Bitcoin has to win, you understand why it is so important for the fiat 
                          world order to be dismantled. The world is shaped and history is written by a small handful of people. 
                          Now is the time to fight for the world to use a Bitcoin standard monetary policy.<br /><br />
                        
                          In 1776 the average person living in the British colonies in America didn’t care that they were paying 
                          taxes to the king. They didn’t care that they couldn’t vote, or govern themselves. It was a small 
                          group of individuals who cared a great deal that set out to make their lives better, which in turn 
                          made their peers’ lives better as well. We find ourselves in a similar situation today. Most people 
                          know nothing about fiat currency, or how their worth is stolen from them with each new dollar printed. 
                          But there is a group of people who do understand and will fight to make everyone's life better. 
                          Satoshi is Thomas Jefferson, Bitcoin is the United States Constitution, the Bitcoin whitepaper is 
                          the Declaration of Independence, and Effem is Paul Revere riding a horse as fast as we can and screaming 
                          at the top of our lungs, “the fiat world order is dying, join us in the Bitcoin ecosystem and be saved!”

                          </p>
                      </div>
                      <div
                          id="why-music"
                          ref={whyMusicRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                          Why
                          <span className="text-gradient from-purple-600 to-pink-600">
                           <a href='/music'> music</a></span>? <br />
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">

                          Music is a universal language, breaking barriers and uniting humanity in inexplicable ways. 
                          Its mass appeal serves as an ideal platform to introduce individuals to the potential of 
                          Bitcoin for broader adoption. Today's music streaming industry is grossly flawed; 
                          artists shouldn't be dependent on mega-stardom or beholden to major labels to make a living. 
                          Effem offers a solution, enabling artists to focus on cultivating their fan base rather than 
                          chasing mass streams. By eliminating middlemen, Effem allows for direct, instant, and permission-less 
                          value transfer between artists and fans. This fosters meaningful connections between artists and 
                          their most dedicated supporters, a crucial aspect often overlooked in mainstream streaming platforms. 
                          Through Effem Music, we empower artists to regain control over their artistry while inviting users to 
                          join a journey where each stream propels the Bitcoin revolution forward.

                          </p>
                      </div>

                      <div
                          id="into-the-future"
                          ref={intoTheFutureRef}
                          className="pt-16"
                      >
                          <h2 className="my-4 text-lg tracking-wider text-gray-500 md:text-xl lg:text-2xl">
                          Into the Future:
                          </h2>
                          <p className="text-[12px] lg:text-[14px] text-white tracking-wider leading-loose font-light">

                          Beyond music, our vision extends to disrupting other creator industries, such as journalism. 
                          From there, we will unleash our suite of SaaS offerings, built on a foundation of transparency 
                          and decentralization. To understand our SAAS offerings, please see our 
                          <span className="text-gradient from-purple-600 to-pink-600">
                          <a href='/tech-stack'> tech stack</a></span> and head over 
                          to 
                          <span className="text-gradient from-purple-600 to-pink-600">
                          <a href='https://swift.cloud'> swift.cloud </a></span>
                          to learn more about our infrastructure. We will build that tech stack in the 
                          open, but also provide SAAS offerings for others who wish to build their business on top of 
                          Bitcoin and/or Nostr.<br /><br />
                          Effem's journey is just beginning, but our resolve is unwavering. Join us in rewriting the 
                          narrative of the digital age, where freedom, empowerment, and prosperity reign supreme.
                          
                          </p>
                      </div>
                      
                      <div className="pt-16 pb-24">
                          <Button
                              text="Contact Us"
                              link="/contact"
                              variant="gradient"
                              shadow={true}
                          />
                      </div>
                  </div>
              </div>
          </section>
      </Layout>
  );
}

export default Manifesto