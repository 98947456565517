import { useNavigate } from "react-router-dom";


const Button = ({ text, link, variant, shadow = false, fullWidth = false }) => {

    const navigate = useNavigate();

    let buttonContainerClasses = "group h-12 leading-12 rounded-lg transition duration-300 ease-in-out";
    let buttonChildClasses = "h-full px-8 flex items-center justify-center rounded-lg transition duration-300 ease-in-out";

    if (variant === "gradient") {
        buttonContainerClasses += " text-white p-0.5 bg-gradient-to-r from-purple-600 to-pink-600";
        buttonChildClasses += " relative bg-black group-hover:bg-transparent";
    } else {
        buttonContainerClasses += " text-black bg-gray-300";
        buttonChildClasses += " bg-transparent";
    }

    if (shadow) {
        if (variant === "gradient") {
            buttonContainerClasses += " relative before:transition before:duration-300 before:ease-in-out before:block before:content-[''] before:absolute before:top-[-2px] before:bottom-[-2px] before:left-[-2px] before:right-[-2px] before:rounded-lg before:bg-gradient-to-r before:from-purple-600 before:to-pink-600 before:blur-2xl before:opacity-70 before:hover:opacity-100";
        } else {
            buttonContainerClasses += " shadow-2xl";
        }
    }

    if (fullWidth) {
        buttonContainerClasses += " w-full"
    } else {
        buttonContainerClasses += ""
    }

  return (
    <button
      className={buttonContainerClasses}
      onClick={() => navigate(link)}
    >
        <span className={buttonChildClasses}>
            {text}
        </span>
    </button>
  );
}

export default Button;
