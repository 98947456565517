import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import useDocumentTitle from "./useDocumentTitle";

const Layout = ({ children, title, marginBottomClass }) => {
    const titleSuffix = " - Effem";
    const combinedTitle = title ? `${title}${titleSuffix}` : `Effem`;
    useDocumentTitle(combinedTitle);
    return (
        <>
            <Nav />
            <main className={`${ marginBottomClass ? marginBottomClass : "mb-[100px]" } mx-2 xl:mx-8`}>
                {children}
            </main>
            <Footer />
        </>
    );
};

export default Layout;
