import React from "react";
import { Link } from "react-router-dom";
import {ArrowLeftIcon} from '@heroicons/react/20/solid'

const ComingSoon = () => {
    return (
        <div className="w-full text-center py-40">
            <h1 className="font-mono text-white mx-auto text-2xl lg:text-4xl font-extrabold px-4">
                In the Lab:{" "}
                <span className="text-gradient from-purple-600 to-pink-600">
                    Coming Soon
                </span>
            </h1>
            <p className="text-gray-400 mx-auto mt-8 max-w-4xl text-base lg:text-lg px-4 leading-8">
                Hang tight — our robots should have these new pages updated soon.
            </p>
            <Link
                to="/"
                className="inline-block text-white hover:underline underline-offset-8 mt-12"
            >
                <ArrowLeftIcon className="h-5 w-5 inline-block -mt-1 mr-2" />
                Go Home
            </Link>
        </div>
    );
};

export default ComingSoon;
